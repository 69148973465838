import { Link } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import MissingTopicModal from "./MissingTopicModal";
import FeedbackModal from "./FeedbackModal";

export default function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const [isMissingTopicModalOpen, setMissingTopicModalOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  return (
    <footer className="relative mt-20">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-16 border-t border-slate-800">
          <div className="grid md:grid-cols-12 gap-8 lg:gap-20">
            {/* Navigation Links */}
            <div className="md:col-span-4 lg:col-span-5">
              <div className="mb-2">
                <Link to="/" className="inline-block text-slate-200 text-xl font-bold">
                  {t('footer.title')}
                </Link>
              </div>
              <div className="text-sm text-slate-400">
                {t('footer.copyright', { year: currentYear })}
              </div>
            </div>

            {/* Navigation */}
            <div className="md:col-span-8 lg:col-span-7 grid sm:grid-cols-3 gap-8">
              {/* Navigation Column */}
              <div className="text-sm">
                <h6 className="text-slate-200 font-medium mb-2">{t('footer.navigation.title')}</h6>
                <ul className="space-y-2">
                  <li>
                    <Link to="/" className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out">
                      {t('footer.navigation.today')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms" className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out">
                      {t('footer.navigation.terms')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy" className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out">
                      {t('footer.navigation.privacy')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out">
                      {t('footer.navigation.about')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/roadmap" className="text-slate-400 hover:text-slate-200 transition duration-150 ease-in-out">
                      {t('footer.navigation.roadmap')}
                    </Link>
                  </li>
                </ul>
              </div>

              {/* Contact Column */}
              <div className="text-sm col-span-2">
                <h6 className="text-slate-200 font-medium mb-2">{t('footer.contact.title')}</h6>
                <p className="text-slate-400 mb-4">{t('footer.contact.description')}</p>
                <div className="space-y-4">
                  <a
                    href={`mailto:${t('footer.contact.emailAddress')}`}
                    className="inline-flex items-center text-purple-500 hover:text-purple-400 transition duration-150 ease-in-out group"
                  >
                    {t('footer.contact.email')}
                    <span className="tracking-normal group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">
                      →
                    </span>
                  </a>
                  <div className="pt-0">
                    <button
                      type="button"
                      onClick={() => setIsFeedbackModalOpen(true)}
                      className="inline-flex items-center text-purple-500 hover:text-purple-400 transition duration-150 ease-in-out group"
                    >
                      {t('feedback.link')}
                      <span className="tracking-normal group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">
                        →
                      </span>
                    </button>
                  </div>

                  <div className="border-t border-slate-800 pt-4">
                    <p className="text-slate-400 mb-2">{t('footer.missingTopic.description')}</p>
                    <button
                      type="button"
                      onClick={() => setMissingTopicModalOpen(true)}
                      className="inline-flex items-center text-purple-500 hover:text-purple-400 transition duration-150 ease-in-out group"
                    >
                      {t('footer.missingTopic.suggest')}
                      <span className="tracking-normal group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">
                        →
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MissingTopicModal
        isOpen={isMissingTopicModalOpen}
        onClose={() => setMissingTopicModalOpen(false)}
      />

      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
      />
    </footer>
  );
} 