import { useTranslation } from 'react-i18next';

type SpinnerProps = {
  fullPage?: boolean;
  text?: string;
};

export default function Spinner({ fullPage = true, text }: SpinnerProps) {
  const { t } = useTranslation();
  const displayText = text || t('common.loading');

  const spinnerContent = (
    <div className="flex flex-col items-center justify-center gap-4">
      <div className="relative">
        {/* Outer ring */}
        <div className="w-12 h-12 rounded-full border-4 border-purple-500/20" />
        {/* Inner spinning ring */}
        <div className="absolute top-0 left-0 w-12 h-12 rounded-full border-4 border-t-purple-500 animate-spin" />
      </div>
      <p className="text-slate-400 text-sm animate-pulse">
        {displayText}
      </p>
    </div>
  );

  if (!fullPage) {
    return spinnerContent;
  }

  return (
    <div className="fixed inset-0 bg-slate-900/90 backdrop-blur-sm z-50 flex items-center justify-center">
      {spinnerContent}
    </div>
  );
} 