import { Link, useLocation } from '@remix-run/react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { useLocale } from 'remix-i18next/react';
import clsx from 'clsx';

function Logo() {
  const { t } = useTranslation();

  return (
    <Link className="inline-flex font-bold text-xl" to="/" aria-label={t('header.title')}>
      {t('header.title')}
    </Link>
  )
}

export default function Header() {
  const { t } = useTranslation()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const location = useLocation();
  const locale = useLocale();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <We need that>
  useEffect(() => {
    setMobileMenuOpen(false)
  }, [location.pathname])

  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          <div className="flex-1">
            <Logo />
          </div>

          <div className="relative  md:hidden">
            <button
              type="button"
              className="p-2"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              aria-label="Toggle menu"
            >
              <svg className="w-6 h-6 text-white" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                <path d={mobileMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
              </svg>
            </button>

            <nav className={`${mobileMenuOpen ? 'block' : 'hidden'} absolute right-0 top-full mt-2 w-64 rounded-lg bg-slate-800 py-2 px-3 shadow-lg`}>
              <ul className="flex flex-col space-y-2">
                <li>
                  <Link className="block font-medium text-sm text-slate-300 hover:text-white transition duration-150 ease-in-out" to="/">{t('header.today')}</Link>
                </li>
                <li>
                  <Link className="block font-medium text-sm text-slate-300 hover:text-white transition duration-150 ease-in-out" to="/years">{t('header.years')}</Link>
                </li>
                <li>
                  <Link className="block font-medium text-sm text-slate-300 hover:text-white transition duration-150 ease-in-out" to="/calendar">{t('header.calendar')}</Link>
                </li>
                <li>
                  <Link className="block font-medium text-sm text-slate-300 hover:text-white transition duration-150 ease-in-out" to="/collections">{t('header.collections')}</Link>
                </li>
                <li>
                  <Link className="block font-medium text-sm text-slate-300 hover:text-white transition duration-150 ease-in-out" to="/recurring/events">{t('header.recurring')}</Link>
                </li>
                <li>
                  <Link className="block font-medium text-sm text-slate-300 hover:text-white transition duration-150 ease-in-out" to="/tags">{t('header.tags')}</Link>
                </li>
              </ul>
            </nav>
          </div>

          <nav className="hidden md:flex md:grow">
            <ul className="flex grow justify-center flex-wrap items-center">
              <li>
                <Link className="font-medium text-sm text-slate-300 hover:text-white mx-4 lg:mx-5 transition duration-150 ease-in-out" to="/">{t('header.today')}</Link>
              </li>
              <li>
                <Link className="font-medium text-sm text-slate-300 hover:text-white mx-4 lg:mx-5 transition duration-150 ease-in-out" to="/years">{t('header.years')}</Link>
              </li>
              <li>
                <Link className="font-medium text-sm text-slate-300 hover:text-white mx-4 lg:mx-5 transition duration-150 ease-in-out" to="/calendar">{t('header.calendar')}</Link>
              </li>
              <li>
                <Link className="font-medium text-sm text-slate-300 hover:text-white mx-4 lg:mx-5 transition duration-150 ease-in-out" to="/collections">{t('header.collections')}</Link>
              </li>
              <li>
                <Link
                  className="font-medium text-sm text-slate-300 hover:text-white mx-4 lg:mx-5 transition duration-150 ease-in-out"
                  to="/recurring/events"
                >
                  {t('header.recurring')}
                </Link>
              </li>
              <li>
                <Link className="font-medium text-sm text-slate-300 hover:text-white mx-4 lg:mx-5 transition duration-150 ease-in-out" to="/tags">{t('header.tags')}</Link>
              </li>
            </ul>
          </nav>

          <div className="hidden md:flex flex-1 justify-end items-center">
            <div className="hidden md:block text-sm text-slate-300 text-right">
              {t('header.todayIs', { date: DateTime.now().toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' }, { locale }) })}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
