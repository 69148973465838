import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { trackEvent } from '~/utils/analytics'

type FeedbackModalProps = {
  isOpen: boolean
  onClose: () => void
}

export default function FeedbackModal({ isOpen, onClose }: FeedbackModalProps) {
  const { t } = useTranslation()
  const [feedback, setFeedback] = useState('')
  const [email, setEmail] = useState('')
  const [showEmailField, setShowEmailField] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState(false)
  const [alreadySubmitted, setAlreadySubmitted] = useState(false)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!feedback.trim()) return

    setIsSubmitting(true)
    setError(null)
    setSuccess(false)
    setAlreadySubmitted(false)

    try {
      const response = await fetch('/api/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          content: feedback.trim(),
          email: email.trim() || undefined 
        }),
      })

      if (response.status === 400) {
        const data = await response.json();
        if (data.error === 'already_submitted') {
          setAlreadySubmitted(true);
          return;
        }
      }

      if (!response.ok) {
        throw new Error('Failed to submit')
      }

      trackEvent('feedback_submitted');

      setSuccess(true)
      setFeedback('')
      setEmail('')
      setTimeout(() => {
        onClose()
      }, 5000)
    } catch (err) {
      setError(t('feedback.error'))
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <DialogPanel className="bg-slate-900 rounded-lg p-6 max-w-md w-full">
          <DialogTitle className="text-xl font-semibold mb-4">
            {t('feedback.title')}
          </DialogTitle>

          {alreadySubmitted ? (
            <div className="text-center py-4">
              <div className="text-amber-400 mb-2">
                <svg className="w-12 h-12 mx-auto mb-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" role="img">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
                <h3 className="text-lg font-medium">{t('feedback.alreadySubmitted.title')}</h3>
              </div>
              <p className="text-slate-400 mb-4">{t('feedback.alreadySubmitted.message')}</p>
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-slate-400 hover:text-slate-300"
              >
                {t('common.close')}
              </button>
            </div>
          ) : success ? (
            <div className="text-center py-4">
              <div className="text-green-400 mb-2">
                <svg className="w-12 h-12 mx-auto mb-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" role="img">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <h3 className="text-lg font-medium">{t('feedback.success.title')}</h3>
              </div>
              <p className="text-slate-400">{t('feedback.success.message')}</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <p className="text-slate-400 mb-4">
                {t('feedback.description')}
              </p>
              
              <div className="mb-4">
                <label htmlFor="feedback" className="block text-sm font-medium text-slate-300 mb-2">
                  {t('feedback.contentLabel')}
                </label>
                <textarea
                  id="feedback"
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  rows={4}
                  className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-md focus:ring-2 focus:ring-purple-500/50 focus:border-purple-500/50"
                  placeholder={t('feedback.placeholder')}
                />
              </div>

              <div className="mb-4">
                <button
                  type="button"
                  onClick={() => setShowEmailField(!showEmailField)}
                  className="text-sm text-purple-400 hover:text-purple-300 transition-colors"
                >
                  {showEmailField ? t('feedback.hideEmail') : t('feedback.showEmail')}
                </button>

                {showEmailField && (
                  <div className="mt-2">
                    <label htmlFor="email" className="block text-sm font-medium text-slate-300 mb-2">
                      {t('feedback.emailLabel')}
                    </label>
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-md focus:ring-2 focus:ring-purple-500/50 focus:border-purple-500/50"
                      placeholder={t('feedback.emailPlaceholder')}
                    />
                    <p className="mt-1 text-xs text-slate-400">
                      {t('feedback.emailHelp')}
                    </p>
                  </div>
                )}
              </div>

              {error && (
                <div className="text-red-400 text-sm mb-4">
                  {error}
                </div>
              )}

              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 text-slate-400 hover:text-slate-300"
                >
                  {t('common.cancel')}
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-4 py-2 bg-purple-500/10 text-purple-400 hover:bg-purple-500/20 rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isSubmitting ? t('common.submitting') : t('feedback.submit')}
                </button>
              </div>
            </form>
          )}
        </DialogPanel>
      </div>
    </Dialog>
  )
} 