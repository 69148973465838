import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { trackEvent } from '~/utils/analytics'

type MissingTopicModalProps = {
  isOpen: boolean
  onClose: () => void
}

export default function MissingTopicModal({ isOpen, onClose }: MissingTopicModalProps) {
  const { t } = useTranslation()
  const [topic, setTopic] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState(false)
  const [alreadySubmitted, setAlreadySubmitted] = useState(false)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!topic.trim()) return

    setIsSubmitting(true)
    setError(null)
    setSuccess(false)
    setAlreadySubmitted(false)

    try {
      const response = await fetch('/api/missing-topic', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ topic: topic.trim() }),
      })

      if (response.status === 400) {
        setAlreadySubmitted(true)
        return
      }

      if (!response.ok) {
        throw new Error('Failed to submit')
      }

      trackEvent('missing_topic', { topic: topic.trim() });

      setSuccess(true)
      setTopic('')
      setTimeout(() => {
        onClose()
      }, 5000)
    } catch (err) {
      setError(t('missingTopic.error'))
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <DialogPanel className="bg-slate-900 rounded-lg p-6 max-w-md w-full">
          <DialogTitle className="text-xl font-semibold mb-4">
            {t('missingTopic.title')}
          </DialogTitle>

          {alreadySubmitted ? (
            <div className="text-center py-4">
              <div className="text-amber-400 mb-2">
                <svg className="w-12 h-12 mx-auto mb-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" role="img">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
                <h3 className="text-lg font-medium">{t('missingTopic.alreadySubmitted.title')}</h3>
              </div>
              <p className="text-slate-400 mb-4">{t('missingTopic.alreadySubmitted.message')}</p>
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-slate-400 hover:text-slate-300"
              >
                {t('common.close')}
              </button>
            </div>
          ) : success ? (
            <div className="text-center py-4">
              <div className="text-green-400 mb-2">
                <svg className="w-12 h-12 mx-auto mb-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" role="img">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <h3 className="text-lg font-medium">{t('missingTopic.success.title')}</h3>
              </div>
              <p className="text-slate-400">{t('missingTopic.success.message')}</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <p className="text-slate-400 mb-4">
                {t('missingTopic.description')}
              </p>
              
              <div className="mb-4">
                <label htmlFor="topic" className="block text-sm font-medium text-slate-300 mb-2">
                  {t('missingTopic.topicLabel')}
                </label>
                <input
                  type="text"
                  id="topic"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  className="w-full px-3 py-2 bg-slate-800 border border-slate-700 rounded-md focus:ring-2 focus:ring-purple-500/50 focus:border-purple-500/50"
                  placeholder={t('missingTopic.placeholder')}
                />
              </div>

              {error && (
                <div className="text-red-400 text-sm mb-4">
                  {error}
                </div>
              )}

              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 text-slate-400 hover:text-slate-300"
                >
                  {t('common.cancel')}
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-4 py-2 bg-purple-500/10 text-purple-400 hover:bg-purple-500/20 rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isSubmitting ? t('common.submitting') : t('missingTopic.submit')}
                </button>
              </div>
            </form>
          )}
        </DialogPanel>
      </div>
    </Dialog>
  )
} 