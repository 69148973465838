import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import {
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  isRouteErrorResponse,
  useRouteError,
} from "@remix-run/react";
import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import { useTranslation } from "react-i18next";
import i18next from "~/i18next.server";
import { Settings } from 'luxon';
import { useGlobalNavigationState } from "remix-utils/use-global-navigation-state";

import styles from "./tailwind.css?url";
import Header from "./components/Header";
import { useEffect } from "react";
import AOS from 'aos'
import 'aos/dist/aos.css'
import Footer from "./components/Footer";
import Spinner from "./components/Spinner";
import { default as posthog } from "posthog-js";
export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
  {
    rel: "icon",
    type: "image/x-icon",
    href: "/favicon.ico"
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "16x16",
    href: "/favicon-16x16.png"
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    href: "/favicon-32x32.png"
  },
  {
    rel: "apple-touch-icon",
    sizes: "192x192",
    href: "/android-chrome-192x192.png"
  },
  {
    rel: "manifest",
    href: "/site.webmanifest"
  },
  {
    rel: "stylesheet",
    href: styles
  }
];

const COOKIE_CONSENT_URL = process.env.HOST_URL;

export async function loader({ request }: LoaderFunctionArgs) {
  const locale = await i18next.getLocale(request);

  Settings.defaultLocale = locale;

  return json({
    locale: locale || 'en',
    analyticsUrl: process.env.ANALYTICS_URL,
    analyticsWebsiteId: process.env.ANALYTICS_WEBSITE_ID,
    cookieConsentUrl: `${COOKIE_CONSENT_URL}/${process.env.COOKIE_CONSENT_ID}.js`,
    cdnUrl: process.env.CDN_URL,
  });
}

export const handle = {
  i18n: "common",
};

function PosthogInit({ analyticsUrl, analyticsWebsiteId }: { analyticsUrl: string | undefined, analyticsWebsiteId: string | undefined }) {
  useEffect(() => {
    if (!analyticsUrl || !analyticsWebsiteId) return;

    posthog.init(analyticsWebsiteId, {
      api_host: analyticsUrl,
      person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
    });
  }, [analyticsUrl, analyticsWebsiteId]);

  return null;
}

export function Layout({ children }: { children: React.ReactNode }) {
  const { analyticsUrl, analyticsWebsiteId, cookieConsentUrl } = useLoaderData<typeof loader>();
  const states = useGlobalNavigationState();

  const { i18n } = useTranslation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 1000,
      easing: 'ease-out-cubic',
    })
  })

  return (
    <html lang={i18n.language} className="scroll-smooth">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        {/* biome-ignore lint/style/useSelfClosingElements: <Should be this way> */}
        <script type="text/javascript" src={cookieConsentUrl}></script>
      </head>
      <body className="font-inter antialiased bg-slate-900 text-slate-100 tracking-tight">
        <div className="flex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip">
          <Header />
          <main className="grow mt-28">
            <div className="absolute inset-0 overflow-hidden pointer-events-none -z-10" aria-hidden="true">
              <div className="absolute flex items-center justify-center top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 w-1/3 aspect-square">
                <div className="absolute inset-0 translate-z-0 bg-purple-500 rounded-full blur-[120px] opacity-50" />
              </div>
            </div>
            {states.includes('loading') ? <Spinner /> : children}
          </main>
          <Footer />
        </div>
        <ScrollRestoration />
        <Scripts />
        <PosthogInit analyticsUrl={analyticsUrl} analyticsWebsiteId={analyticsWebsiteId} />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();


  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <html lang="en" className="scroll-smooth">
          <head>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <Meta />
            <Links />
          </head>
          <body className="font-inter antialiased bg-slate-900 text-slate-100 tracking-tight">
            <div className="flex flex-col min-h-screen">
              <Header />
              <main className="grow">
                <div className="container mx-auto px-4 py-16 text-center">
                  <h1 className="text-4xl font-bold mb-4">404</h1>
                  <p className="text-lg mb-8">Page not found</p>
                  <a href="/" className="text-blue-400 hover:text-blue-300 underline">
                    Back to homepage
                  </a>
                </div>
              </main>
              <Footer />
            </div>
            <ScrollRestoration />
            <Scripts />
          </body>
        </html>
      );
    }
  }

  captureRemixErrorBoundaryError(error);

  return (
    <html lang="en" className="scroll-smooth">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="font-inter antialiased bg-slate-900 text-slate-100 tracking-tight">
        <div className="flex flex-col min-h-screen">
          <Header />
          <main className="grow">
            <div className="container mx-auto px-4 py-16 text-center">
              <h1 className="text-4xl font-bold mb-4">500</h1>
              <p className="text-lg mb-8">Something went wrong</p>
              <a href="/" className="text-blue-400 hover:text-blue-300 underline">
                Back to homepage
              </a>
            </div>
          </main>
          <Footer />
        </div>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export default withSentry(App);